<template>
    <div class="passenger_box_two mt-2">
        <div class="passenger_body" :style="`background-image: url(${speedSizeDomain}/assets/img/passenger-box-banner.jpg)`">
            <UserInformation
                v-for="(user, idx) in users"
                :key="idx"
                :index="idx"
                :user="user"
                :originDate="originDate"
                :readOnly="readOnly"
                @updateUser="updateUserInformation"
            />
        </div>
    </div>

</template>

<script>
import dayjs from 'dayjs';
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  mixins: [imageUrlMixin],
  components: {
    UserInformation: () => import('@/components/booking/atoms/UserInformation'),
  },
  props: {
    flight: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      users: [],
      readOnly: false,
      isDuplicated: false,
    };
  },
  computed: {
    originDate() {
      return dayjs(this.flight.departureDate, 'YYYY-MM-DD').format('DD-MM-YYYY');
    },
  },
  created() {
    const retry = window.sessionStorage.getItem('retryState'),
      dataBook = JSON.parse(window.sessionStorage.getItem('BookingDetailInfo')),
      BookingDetailInfo = dataBook.data,
      { sendingStage } = dataBook,
      { paxIds } = BookingDetailInfo.outwardFlight;
    // retry = {readOnly: true};
    if (retry !== 'null' && paxIds.length > 0 && sendingStage !== 'init') {
      const retryState = JSON.parse(retry);
      this.readOnly = retryState.readOnly;
      for (let i = 0; i < paxIds.length; i += 1) {
        const user = BookingDetailInfo.paxList[i];
        user.groupID = this.index;
        this.users.push(user);
      }
    } else {
      let idx = 1;
      for (let i = 0; i < this.flight.adult; i += 1) {
        this.users.push({
          groupID: this.index,
          id: idx,
          generation: 'adult',
          firstName: '',
          lastName: '',
          paxType: 'M',
          email: '',
          phone: '',
          birthDate: '',

        });
        idx += 1;
      }
      for (let i = 0; i < this.flight.child; i += 1) {
        this.users.push({
          groupID: this.index,
          id: idx,
          generation: 'child',
          firstName: '',
          lastName: '',
          paxType: 'M',
          email: '',
          phone: '',
          birthDate: '',
        });
        idx += 1;
      }
      for (let i = 0; i < this.flight.infant; i += 1) {
        this.users.push({
          groupID: this.index,
          id: idx,
          generation: 'infant',
          firstName: '',
          lastName: '',
          paxType: 'M',
          email: '',
          phone: '',
          birthDate: '',
        });
        idx += 1;
      }
    }
    this.users.forEach((user) => this.$emit('updatedUser', user));
  },
  methods: {
    updateUserInformation(pInfo) {
      const target = this.users.filter((user) => user.id === pInfo.id && user.groupID === pInfo.groupID)[0];
      Object.assign(target, pInfo);
      this.$emit('updatedUser', pInfo);
      if (pInfo.id === 1 && pInfo.validate && !this.isDuplicated) {
        for (let i = 1; i < this.users.length; i += 1) {
          // const { id } = this.users[i];
          // Object.assign(this.users[i], pInfo);
          // this.users[i].id = id;
          this.users[i].email = pInfo.email;
          this.users[i].phone = pInfo.phone;
          this.$emit('updatedUser', this.users[i]);
        }
        this.isDuplicated = true;
      }
    },
  },
};
</script>

<style scoped>
    .passenger_header {
        padding: 20px 20px;
        background: rgb(2,0,36);
        background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(1,98,172,1) 0%, rgba(38,135,209,1) 100%);
    }

    .passenger_body {
        padding: 20px 20px;
        /*min-height: 334px;*/
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        position: relative;
    }

    .passenger_body:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgb(11 102 172 / 95%);
    }

    @media (max-width: 479px){
        .passenger_header {
            background : #fff;
            padding : 5px 15px
        }
        .guest_details_body h4 {
            color: rgb(33,37,41);
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 10px;
        }
        .passenger_body {
            padding : 0px 15px;
        }

        .passenger_body:before{
            background:#fff;
        }
    }

</style>
